import ZipEntry from '@/components/design-system/ZipEntry'
import StarRating from '@/components/design-system/rating/StarRating'
import { Curve } from '@/components/design-system/Curve'

const HeroSectionNewToTxu = () => {
  return (
    <div className="u-relative u-flex u-h-[41rem] u-flex-col u-items-center u-bg-new-to-txu-hero-mobile u-bg-cover u-bg-no-repeat u-px-6 u-py-10 md:u-h-[39.4375rem] md:u-bg-new-to-txu-hero md:u-bg-center md:u-py-16 lg:u-h-[42.8125rem] lg:u-py-20 xl:u-h-[45rem]">
      <div className="u-flex u-max-w-[20.4375rem] u-flex-col u-gap-y-6 md:u-max-w-[32.875rem] md:u-items-center lg:u-max-w-[36.5rem]">
        <h1 className="t-title-2xl md:t-title-3xl lg:t-title-4xl u-text-center u-text-white md:u-w-10/12 lg:-u-mb-1 lg:u-w-[unset]">
          Save with Texans&#39; #1 electricity choice
        </h1>
        <ol className="u-flex u-flex-col u-items-center u-gap-y-2 md:u-flex-row md:u-gap-x-6">
          <li className="t-body-base-bold u-text-white">1. Enter ZIP Code</li>
          <li className="t-body-base-bold u-text-white">
            2. Select your energy plan
          </li>
          <li className="t-body-base-bold u-text-white">
            3. Sign up in minutes
          </li>
        </ol>
        <div className="u-flex u-flex-col u-gap-y-6 md:u-w-[31.875rem] lg:u-w-[36.5rem]">
          <ZipEntry type="newToTXU" />
        </div>
        <div className="u-self-center">
          <StarRating variant="new-to-txu-hero" rating={4.7} />
        </div>
      </div>
      <div className="u-absolute u-bottom-0 u-left-0 u-right-0 u-w-full">
        <Curve variant={13} />
      </div>
    </div>
  )
}

export default HeroSectionNewToTxu
