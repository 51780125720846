"use client";

import React from "react"
import Image, { ImageProps } from "next/image"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

type JOFeaturedCardContextValue = {
    variant: "default" | "compact"
}

const JOFeaturedCardContext = React.createContext<JOFeaturedCardContextValue | undefined>(undefined)

type JOFeaturedCardContextProviderProps = JOFeaturedCardContextValue & {
    children: React.ReactNode
}

export const JOFeaturedCardContextProvider = ({ variant, children }: JOFeaturedCardContextProviderProps) => {
    return (
        <JOFeaturedCardContext.Provider
            value={{
                variant
            }}
        >
            {children}
        </JOFeaturedCardContext.Provider>
    )
}

export const useJOFeaturedCardContext = () => {
    const context = React.useContext(JOFeaturedCardContext)

    if (!context) {
        throw new Error("useJOFeaturedCardContext must be used within a JOFeaturedCardContext Provider")
    }

    return context
}

const JOFeaturedCardVariants = cva(
    cn(
        "u-flex u-flex-col u-justify-start u-gap-3 u-bg-gray-150 u-p-2 u-pb-4 u-rounded-md u-relative",
    ),
    {
        variants: {
            variant: {
                default: "sm:u-px-4 sm:u-pt-4 sm:u-pb-6 sm:u-grow sm:u-max-w-[14.063rem] lg:u-max-w-[23.438rem] lg:u-p-6",
                compact: cn(
                    "u-grid u-grid-cols-[48px_1fr] u-grid-rows-2 u-col-gap-4 u-bg-white u-shadow-e6 u-py-5 u-pt-6 u-px-3 u-rounded-[0.688rem]",
                    "lg:u-grid-cols-[64px_1fr] lg:u-pt-10 lg:u-pb-6 lg:u-pl-5 lg:u-pr-4"
                ),
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)

export interface JOFeaturedCardProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof JOFeaturedCardVariants> {
}

export const JOFeaturedCard = ({ children, className, variant, ...props }: JOFeaturedCardProps) => {
    return (
        <JOFeaturedCardContextProvider variant={variant || "default"}>
            <div
                data-cy="jo-featured-card"
                {...props}
                className={cn(JOFeaturedCardVariants({ variant, className }))}
            >
                {children}
            </div>
        </JOFeaturedCardContextProvider>
    )
}

// title
const JOFeaturedCardTitleVariants = cva(
    "u-font-900 u-text-blue-600",
    {
        variants: {
            variant: {
                default: "",
                compact: "u-text-sm u-tracking-normal lg:u-text-[1.188rem]",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
export type JOFeaturedCardTitleProps = React.HTMLAttributes<HTMLHeadingElement>

export const JOFeaturedCardTitle = ({ children, className, ...props }: JOFeaturedCardTitleProps) => {
    const { variant } = useJOFeaturedCardContext()

    return (
        <h3
            data-cy="jo-featured-card-title"
            {...props}
            className={cn(JOFeaturedCardTitleVariants({ variant, className }))}
        >
            {children}
        </h3>
    )
}

// picture
const JOFeaturedCardPictureVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "u-rounded-md u-w-full u-h-24 u-object-cover u-object-center sm:u-h-[11.875rem]",
                compact: "u-row-span-2 u-self-center u-w-12 u-h-12 lg:u-w-16 lg:u-h-16",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
export type JOFeaturedCardPictureProps = ImageProps

export const JOFeaturedCardPicture = ({ className, ...props }: JOFeaturedCardPictureProps) => {
    const { variant } = useJOFeaturedCardContext()

    return (
        <Image
            data-cy="jo-featured-card-picture"
            {...props}
            className={cn(JOFeaturedCardPictureVariants({ variant, className }))}
        />
    )
}

// badge
const JOFeaturedCardBadgeVariants = cva(
    "u-flex u-gap-2 u-items-center u-absolute u-py-1 u-px-2 u-h-7 u-bg-white u-rounded-sm u-border u-border-green-100 u-text-sm",
    {
        variants: {
            variant: {
                default: "u-top-5 u-left-5 sm:u-top-7 sm:u-left-7 lg:u-top-9 lg:u-left-9",
                compact: "sm:u-left-auto md:u-left-auto lg:u-left-auto u-top-2 u-right-3 u-bg-[#F2FBF8] u-text-[0.625rem] u-leading-3 u-py-[0.188rem] u-gap-1.5 u-rounded-[0.219rem] u-border-[0.055rem] u-h-[1.125rem] u-px-1.5 lg:u-text-[0.688rem] lg:u-h-[1.438rem]",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)

interface JOFeaturedCardBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
}

export const JOFeaturedCardBadge = ({ children, className, ...props }: JOFeaturedCardBadgeProps) => {
    const { variant } = useJOFeaturedCardContext()

    return (
        <div
            data-cy="jo-featured-card-badge"
            {...props}
            className={cn(JOFeaturedCardBadgeVariants({ variant, className }))}
        >
            {children}
        </div>
    )
}

// content
const JOFeaturedCardContentVariants = cva(
    "u-px-2",
    {
        variants: {
            variant: {
                default: "",
                compact: "u-row-span-2 u-self-center u-flex u-flex-col u-gap-1 u-p-0",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
export type JOFeaturedCardContentProps = React.HTMLAttributes<HTMLDivElement>

export const JOFeaturedCardContent = ({ children, className, ...props }: JOFeaturedCardContentProps) => {
    const { variant } = useJOFeaturedCardContext()

    return (
        <div
            data-cy="jo-featured-card-content"
            {...props}
            className={cn(JOFeaturedCardContentVariants({ variant, className }))}
        >
            {children}
        </div>
    )
}

// list
export type JOFeaturedCardListProps = React.HTMLAttributes<HTMLUListElement>

export const JOFeaturedCardList = ({ children, className, ...props }: JOFeaturedCardListProps) => {
    return (
        <ul
            data-cy="jo-featured-card-list"
            {...props}
            className={
                cn(
                    "u-list-none u-flex u-flex-col u-gap-1 u-mt-2 u-font-400 u-text-gray-600 u-text-sm",
                    className
                )
            }
        >
            {children}
        </ul>
    )
}

// item
export type JOFeaturedCardListItemProps = React.HTMLAttributes<HTMLLIElement>

export const JOFeaturedCardListItem = ({ children, className, ...props }: JOFeaturedCardListItemProps) => {
    return (
        <li
            data-cy="jo-featured-card-list-item"
            {...props}
            className={
                cn(
                    "u-flex u-items-center u-gap-2 u-pr-2",
                    className
                )
            }
        >
            {children}
        </li>
    )
}

// description
const JOFeaturedCardDescriptionVariants = cva(
    "u-font-400 u-text-gray-600 u-text-3 md:u-text-4",
    {
        variants: {
            variant: {
                default: "",
                compact: "",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
interface JOFeaturedCardDescriptionProps extends React.HTMLAttributes<HTMLParagraphElement> {}

export const JOFeaturedCardDescription: React.FC<JOFeaturedCardDescriptionProps> = ({ children, className, ...props }) => {
    const { variant } = useJOFeaturedCardContext()

    return (
        <p
            data-cy="jo-featured-card-description"
            {...props}
            className={cn(JOFeaturedCardDescriptionVariants({ variant, className }))}
        >
            {children}
        </p>
    )
}

