import Link from 'next/link'
import {
  OtherLinkCard,
  OtherLinkCardBody,
  OtherLinkCardHeader,
  OtherLinkCardTitle,
} from '@/components/design-system/OtherLinkCard'
import CustomIcon from '@/components/design-system/CustomIcon'
import { cn } from '@/lib/utils'
import { GetFuseLease } from '@/helpers/fuse'
import getFormattedPhoneNumber from '@/helpers/getFormattedPhoneNumber'

const OtherLinksSection = () => {
  const phoneNumber = GetFuseLease()
  const formattedPhoneNumber = getFormattedPhoneNumber(phoneNumber)

  return (
    <section className="u-mx-auto u-flex u-max-w-[72rem] u-flex-col u-gap-2 u-p-6 md:u-gap-12 md:u-px-8 md:u-py-10 lg:u-pb-16 lg:u-pt-20">
      <h1 className="t-title-lg md:t-title-2xl lg:t-title-3xl u-mb-4 u-text-balance u-text-center u-text-blue-600 md:u-text-wrap md:u-px-2">
        Our plans help you use less & save more
      </h1>
      <div className="u-grid u-grid-cols-2 u-gap-4 md:u-grid-cols-3 md:u-gap-6 md:u-gap-8">
        <OtherLinkCard>
          <OtherLinkCardHeader>
            <OtherLinkCardTitle>Free Nights & Solar Days</OtherLinkCardTitle>
          </OtherLinkCardHeader>
          <OtherLinkCardBody>
            <p className="md:u-pr-4">
              Sleep cool with 100% free energy at night and enjoy 100% solar
              during the day, no panels required.
            </p>
            <Link
              href="/"
              className="t-title-tiny-black md:t-body-lg-black u-flex u-items-center u-gap-1 u-text-blue-500"
            >
              Learn More
              <CustomIcon icon="arrowRightThin" />
            </Link>
          </OtherLinkCardBody>
        </OtherLinkCard>
        <OtherLinkCard>
          <OtherLinkCardHeader>
            <OtherLinkCardTitle>Ultimate Season Pass</OtherLinkCardTitle>
          </OtherLinkCardHeader>
          <OtherLinkCardBody>
            <p>
              Get 50% off your energy charge during winter & summer, plus even
              more months of automatic savings.
            </p>
            <Link
              href="/"
              className="t-title-tiny-black md:t-body-lg-black u-flex u-items-center u-gap-1 u-text-blue-500"
            >
              Learn More
              <CustomIcon icon="arrowRightThin" />
            </Link>
          </OtherLinkCardBody>
        </OtherLinkCard>
        <OtherLinkCard className="u-col-span-2 u-bg-blue-50 md:u-col-span-1">
          <OtherLinkCardHeader className="u-px-6 u-pt-6">
            <OtherLinkCardTitle className="u-text-5 u-text-indigo-700">
              Speak with an expert
            </OtherLinkCardTitle>
          </OtherLinkCardHeader>
          <OtherLinkCardBody className="u-gap-8 u-px-6 u-pb-6 md:u-gap-0">
            <p className="u-text-4 lg:u-text-5">
              Our top-rated team can help you find the right plan for your life.
            </p>
            <a
              data-fuse
              data-fuse-format="###-###-####"
              data-fuse-name="TXU-Banner"
              href={`tel:${phoneNumber}`}
              className={cn(
                'u-flex u-w-max u-items-center u-gap-2 u-rounded-full u-bg-blue-500 u-px-5 u-py-2 u-font-900 u-text-white [&_svg]:u-size-4',
                'md:u-border-0 md:u-bg-transparent md:u-p-0 md:u-text-blue-500 md:hover:u-bg-transparent md:focus:u-bg-transparent md:focus:u-outline-0',
              )}
            >
              <CustomIcon icon="cellPhone" />
              {formattedPhoneNumber}
            </a>
          </OtherLinkCardBody>
        </OtherLinkCard>
      </div>
    </section>
  )
}

export default OtherLinksSection
