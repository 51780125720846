'use client'

import ReviewsSection from '@/components/sections/ReviewsSection'
import { Curve } from '@/components/design-system/Curve'
import GeoSection from '@/components/sections/GeoSection'
import PromoSection from '@/components/sections/PromoSection'
import ValuesSection from '@/components/sections/ValuesSection'
import PhoneBannerSection from '@/components/sections/PhoneBannerSection'
import FooterSection from '@/components/sections/FooterSection'
import HeaderSection from '@/components/sections/HeaderSection'
import HeroSwitcher from '@/components/sections/HeroSwitcher'
import SectionSwitcher from '@/components/sections/SectionSwitcher'

function Page() {
  return (
    <>
      <HeaderSection />
      <PhoneBannerSection />
      <HeroSwitcher />
      <SectionSwitcher section="firstSection" />
      <ReviewsSection />
      <div className="u-relative u-h-20">
        <div className="u-absolute u-bottom-0 u-left-0 u-right-0 u-top-0 u-z-[-1] lg:u-bottom-0">
          <Curve variant={8} className={'u-h-full'} />
        </div>
      </div>
      <PromoSection />
      <ValuesSection />
      <GeoSection />
      <Curve variant={6} />
      <FooterSection />
    </>
  )
}

export default Page
