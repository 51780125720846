import { BaseProps } from '@/components/design-system/JOValueProp'
import { cn } from '@/lib/utils'
import CustomIcon, { IconKeys } from '@/components/design-system/CustomIcon'
import { JSX } from 'react'

export interface HighIntentProps extends BaseProps {
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  icon?: IconKeys;
}

const HighIntent: React.FC<HighIntentProps> = ({ icon, description, title, className }) => {
  return (
    <div className={cn("u-w-full u-flex u-gap-x-4 u-items-center", className)} data-cy="high-intent">
      <div className="u-w-10 u-h-10 u-flex-shrink-0" data-cy="icon-container">
        <CustomIcon icon={icon!} data-cy="custom-icon" />
      </div>
      <div className="u-flex u-flex-col u-gap-y-1" data-cy="text-container">
        <span className="u-text-white t-title-tiny-black md:t-title-xs-bold " data-cy="title">{title}</span>
        <p className="u-text-white u-tracking-[0] t-title-micro md:t-title-tiny" data-cy="description">{description}</p>
      </div>
    </div>
  );
};

export default HighIntent;
