import { BaseProps } from '@/components/design-system/JOValueProp'
import CustomIcon, { IconKeys } from '@/components/design-system/CustomIcon'
import { JSX } from 'react'

export interface PriceFocusedProps extends BaseProps {
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  icon?: IconKeys;
}

const PriceFocused: React.FC<PriceFocusedProps> = ({ title, description, icon }) => {
  return (
    <div className="u-w-max u-flex u-flex-col u-gap-y-1" data-cy="price-focused">
      <div className="u-w-8 u-h-8" data-cy="icon-container">
        <CustomIcon icon={icon!} data-cy="custom-icon" />
      </div>
      <span className="u-text-white t-title-xs-bold lg:t-title-sm-black u-whitespace-nowrap" data-cy="title">{title}</span>
      <p className="u-text-white u-tracking-[0] t-title-tiny lg:t-title-xs" data-cy="description">{description}</p>
    </div>
  );
};

export default PriceFocused;
