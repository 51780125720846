import { cn } from "@/lib/utils"

interface OtherLinkCardProps extends React.HTMLAttributes<HTMLDivElement> { }

export const OtherLinkCard: React.FC<OtherLinkCardProps> = ({ children, className, ...props }) => {
    return (
        <div
            className={
                cn(
                    "u-bg-gray-150 u-rounded-md u-max-w-[30rem]",
                    "md:u-flex md:u-flex-col md:u-justify-start",
                    className
                )
            }
            {...props}
        >
            {children}
        </div>
    )
}

interface OtherLinkCardHeaderProps extends React.HTMLAttributes<HTMLDivElement> { }
export const OtherLinkCardHeader: React.FC<OtherLinkCardHeaderProps> = ({ children, className, ...props }) => {
    return (
        <div
            className={cn("u-flex u-items-center u-mb-1 u-pt-3 u-px-3 md:u-pt-6 md:u-px-6", className)}
            {...props}
        >
            {children}
        </div>
    )
}

interface OtherLinkCardTitleProps extends React.HTMLAttributes<HTMLHeadingElement> { }
export const OtherLinkCardTitle: React.FC<OtherLinkCardTitleProps> = ({ children, className, ...props }) => {
    return (
        <h2
            className={
                cn(
                    "t-title-xs-black u-text-gray-700 u-text-balance md:u-text-wrap md:t-title-sm-black lg:t-title-base-black",
                    className
                )
            }
            {...props}
        >
            {children}
        </h2>
    )
}

interface OtherLinkCardBodyProps extends React.HTMLAttributes<HTMLDivElement> { }
export const OtherLinkCardBody: React.FC<OtherLinkCardBodyProps> = ({ children, className, ...props }) => {
    return (
        <div
            className={
                cn(
                    "u-flex u-flex-col u-gap-9 u-px-3 u-pb-3 u-text-gray-600 t-body-sm",
                    "md:u-gap-0 md:u-px-6 md:u-pb-6 md:t-body-base md:u-flex-grow md:u-justify-between md:u-gap-2 lg:t-body-xl",
                    className
                )
            }
            {...props}
        >
            {children}
        </div>
    ) 
}
