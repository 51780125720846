import { Button } from '@/components/design-system/Button'
import { Wizard, Step } from '@/components/design-system/Wizard'
import {
  ZipEntryForm,
  ZipEntryFormButton,
  ZipEntryFormError,
  ZipEntryFormInput,
  ZipEntryFormInputWrapper,
  ZipEntryFormTitle,
} from '@/components/design-system/ZipEntryForm'
import { useZipEntryForm } from '@/hooks/useZipEntryForm'
import { cn } from '@/lib/utils'

type PriceFocusedZipEntryProps = {
  /** callback to be called when the user clicks Find Plans button */
  onFindPlansClick?: (this: void, URL: string) => void
}

const PriceFocusedZipEntry = ({
  onFindPlansClick,
}: PriceFocusedZipEntryProps) => {
  const {
    formState: {
      zipCode,
      showZipCodeError,
      showMovingError,
      showApartmentError,
    },
    handlers,
  } = useZipEntryForm({ onFindPlansClick })

  return (
    <div className="u-rounded-md u-bg-white/10 u-px-4 u-py-6">
      <Wizard>
        {({ nextStep }) => (
          <>
            <Step>
              <ZipEntryForm
                variant="researcher"
                onSubmit={(e) => handlers.handleFirstStepSubmit(e, nextStep)}
              >
                <ZipEntryFormTitle className="md:t-title-tiny-bold u-font-700">
                  Enter your ZIP Code to see plans
                </ZipEntryFormTitle>
                <ZipEntryFormInputWrapper>
                  <ZipEntryFormInput
                    value={zipCode}
                    onChange={handlers.handleZipCodeChange}
                    onFocus={handlers.handleZipCodeFocus}
                    id="zipCodeHero"
                    placeholder="Enter ZIP Code"
                  />
                  <ZipEntryFormButton>Continue</ZipEntryFormButton>
                  <ZipEntryFormError showError={showZipCodeError}>
                    Please enter a valid ZIP Code
                  </ZipEntryFormError>
                </ZipEntryFormInputWrapper>
                <a
                  href="/login"
                  className="t-body-sm u-text-center u-text-white u-underline u-underline-offset-4"
                >
                  Already a customer? Sign In
                </a>
              </ZipEntryForm>
            </Step>
            <Step>
              <form
                onSubmit={handlers.handleSecondStepSubmit}
                className="u-flex u-w-full u-flex-col u-gap-3 u-text-white"
                noValidate
              >
                <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                  <legend className="u-mb-2 u-text-center lg:u-float-left">
                    Are you moving to a new address?
                  </legend>
                  <div className="u-group u-relative u-float-right u-flex u-gap-3">
                    <label
                      data-cy="moving-radio-mYesLabel"
                      id="mYesLabel"
                      className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                    >
                      <input
                        type="radio"
                        value="movey"
                        name="m"
                        required
                        aria-labelledby="mYesLabel"
                        onClick={() =>
                          handlers.handleRadioClick(
                            'Move/Switch',
                            'Yes',
                            'User indicates Yes they are moving',
                          )
                        }
                        className={cn(
                          'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                          "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                        )}
                      />
                      Yes
                    </label>

                    <label
                      data-cy="moving-radio-mNoLabel"
                      id="mNoLabel"
                      className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                    >
                      <input
                        type="radio"
                        value="moven"
                        name="m"
                        required
                        aria-labelledby="mNoLabel"
                        onClick={() =>
                          handlers.handleRadioClick(
                            'Move/Switch',
                            'No',
                            'User indicates No they are not moving',
                          )
                        }
                        className={cn(
                          'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                          "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                        )}
                      />
                      No
                    </label>
                    <ZipEntryFormError
                      showError={showMovingError}
                      className="u-whitespace-nowrap"
                    >
                      Please select one of these options.
                    </ZipEntryFormError>
                  </div>
                </fieldset>
                <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                  <legend className="u-mb-2 u-text-center lg:u-float-left">
                    Are you moving to an apartment?
                  </legend>
                  <div className="u-group u-relative u-float-right u-flex u-gap-3">
                    <label
                      data-cy="apartment-radio-aYesLabel"
                      id="aYesLabel"
                      className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                    >
                      <input
                        type="radio"
                        value="apartmenty"
                        name="a"
                        required
                        aria-labelledby="aYesLabel"
                        className={cn(
                          'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                          "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                        )}
                      />
                      Yes
                    </label>

                    <label
                      data-cy="apartment-radio-aNoLabel"
                      id="aNoLabel"
                      className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                    >
                      <input
                        type="radio"
                        value="apartmentn"
                        name="a"
                        required
                        aria-labelledby="aNoLabel"
                        className={cn(
                          'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                          "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                        )}
                      />
                      No
                    </label>
                    <ZipEntryFormError
                      showError={showApartmentError}
                      className="u-whitespace-nowrap"
                    >
                      Please select one of these options.
                    </ZipEntryFormError>
                  </div>
                </fieldset>
                <Button
                  variant="primary"
                  type="submit"
                  data-cy="find-plans-button"
                  className="u-mt-3 hover:u-bg-blue-500 focus:u-bg-blue-500"
                >
                  Find Plans
                </Button>
              </form>
            </Step>
          </>
        )}
      </Wizard>
    </div>
  )
}

export default PriceFocusedZipEntry
