import PhoneBanner from '@/components/design-system/PhoneBanner'
import CustomIcon from '@/components/design-system/CustomIcon'
import { GetFuseLease } from '@/helpers/fuse'
import getFormattedPhoneNumber from '@/helpers/getFormattedPhoneNumber'

const PhoneBannerSection = () => {
  const phoneNumber = GetFuseLease()
  const formattedPhoneNumber = getFormattedPhoneNumber(phoneNumber)

  return (
    <PhoneBanner>
      <span className="md:u-hidden">Call Now</span>
      <span className="u-hidden md:u-block">
        Call now and ask about our Satisfaction Guarantee
      </span>
      <span className="u-flex u-items-center u-justify-start u-gap-2">
        <CustomIcon icon="cellPhone" className="u-h-5" />
        <a
          data-fuse
          data-fuse-format="###-###-####"
          data-fuse-name="TXU-Banner"
          href={`tel:${phoneNumber}`}
          className="t-title-sm-black lg:t-title-base-black u-leading-5 u-text-white u-underline md:u-leading-6"
        >
          {formattedPhoneNumber}
        </a>
      </span>
    </PhoneBanner>
  )
}

export default PhoneBannerSection
