import { useMonarch } from '@redventures/cohesion-utils-react'
import OtherLinksSection from './OtherLinksSection'
interface JOComponentData {
  hero?: string
  firstSection?: string
  [key: string]: any
}

const SectionSwitcher = ({ section }: { section: string }) => {
  const { rules } = useMonarch()
  const {
    trafficFlow,
  }: { trafficFlow?: { value?: { componentCustomId?: string; data?: any } } } =
    rules
  const isJoComponent =
    trafficFlow?.value?.componentCustomId === 'joHomepageVersion'
  const data: JOComponentData = isJoComponent
    ? (trafficFlow?.value?.data as JOComponentData) || {}
    : {}

  if (data?.[section] === 'OtherLinksSection') {
    return <OtherLinksSection />
  }

  return null
}

export default SectionSwitcher
