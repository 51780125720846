import ZipEntry from '@/components/design-system/ZipEntry'
import JOValueProp from '@/components/design-system/JOValueProp'
import React from 'react'
import StarRating from '@/components/design-system/rating/StarRating'
import { Curve } from '@/components/design-system/Curve'
import Image from 'next/image'
import { openExternalLink } from '@/helpers/setCookieAndLinkOff'

const HeroSectionHighIntent = () => {
  const handleZipCodeClick = (URL: string) => {
    openExternalLink(`/${URL}`)
  }

  return (
    <div className="u-relative u-h-[41.3125rem] u-bg-gradient-to-t-blue-blue md:u-h-[46.9375rem] lg:u-h-[53.25rem]">
      <div className="u-flex u-flex-col u-gap-y-6 u-px-6 u-pb-6 u-pt-10 md:u-gap-y-8 md:u-px-8 md:u-pb-8 md:u-pt-16 lg:u-max-w-[62.5rem] lg:u-gap-y-10 lg:u-justify-self-center lg:u-px-0 lg:u-pb-10 lg:u-pt-20 xl:u-max-w-[75rem]">
        <div className="u-flex u-w-full md:u-items-center md:u-gap-x-6 lg:u-gap-x-[6.25rem]">
          <div className="u-flex u-w-full u-flex-col u-gap-y-6 md:u-w-6/12">
            <h1 className="t-title-2xl md:t-title-3xl lg:t-title-4xl u-text-white">
              Sign up today,
              <br /> save all year
            </h1>
            <div className="u-flex u-flex-col u-items-start md:u-gap-y-6">
              <div className="u-w-full">
                <ZipEntry
                  type="highIntent"
                  onFindPlansClick={handleZipCodeClick}
                />
              </div>
              <div className="u-hidden u-self-center md:u-block md:u-self-start">
                <StarRating variant="high-intent" rating={4.7} />
              </div>
            </div>
          </div>
          <div className="u-relative u-hidden u-w-full md:u-block md:u-h-[21.4375rem] md:u-w-[21.2018rem] lg:u-h-[28.4375rem] lg:u-w-[28.125rem]">
            <Image
              src={`/images/man-woman-dancing.png`}
              alt="Men and woman dancing"
              fill
            />
          </div>
        </div>
        <div className="u-flex u-flex-col u-gap-4 u-gap-y-6 md:u-flex-row md:u-items-center md:u-justify-between">
          <JOValueProp
            variant="high-intent"
            props={{
              title: <StarRating rating={4.7} variant="value-props" />,
              description: (
                <span>
                  Based on 9,000+{' '}
                  <a
                    className="u-underline md:u-whitespace-nowrap"
                    target="_blank"
                    href={''}
                  >
                    Google Reviews
                  </a>
                </span>
              ),
              icon: 'googleWhite',
              className:
                'md:u-hidden md:u-w-[12.5625rem] u-shrink-0 lg:u-w-auto lg:u-shrink-[1]',
            }}
          />

          <JOValueProp
            variant="high-intent"
            props={{
              title: 'Price Protect Promise',
              description: (
                <span>
                  Rest easy with a steady rate protected from rising energy
                  costs.<sup>1</sup>
                </span>
              ),
              icon: 'priceProtectWhite',
              className:
                'u-hidden md:u-flex md:u-w-[12.5625rem] u-shrink-0 lg:u-w-auto lg:u-shrink-[1]',
            }}
          />
          <div className="u-hidden u-h-[2.625rem] u-w-px u-shrink-0 u-bg-white md:u-block" />
          <JOValueProp
            variant="high-intent"
            props={{
              title: 'Total Satisfaction Guarantee',
              description: (
                <span>
                  Try us free for 60 days — no strings attached.<sup>2</sup>
                </span>
              ),
              icon: 'satisfactionWhite',
              className:
                'md:u-w-[12.5625rem] u-shrink-0 lg:u-w-auto lg:u-shrink-[1]',
            }}
          />
          <div className="u-hidden u-h-[2.625rem] u-w-px u-shrink-0 u-bg-white md:u-block" />
          <JOValueProp
            variant="high-intent"
            props={{
              title: 'Same-Day Service',
              description: (
                <span>
                  Call by 6:30 p.m. to get your lights on today.<sup>3</sup>
                </span>
              ),
              icon: 'sameDay',
              className:
                'md:u-w-[12.5625rem] u-shrink-0 lg:u-w-auto lg:u-shrink-[1]',
            }}
          />
        </div>
      </div>
      <div className="u-absolute u-bottom-0 u-left-0 u-right-0 u-w-full">
        <Curve variant={13} />
      </div>
    </div>
  )
}

export default HeroSectionHighIntent
