"use client"
import { BaseProps } from '@/components/design-system/JOValueProp'
import { cn } from '@/lib/utils'
import CustomIcon from '@/components/design-system/CustomIcon'
import { useRouter } from 'next/navigation'
import { GetFuseLease } from '@/helpers/fuse'
import { JSX } from 'react'

export interface ThoughtfulResearchesPhoneProps extends BaseProps {
  title?: JSX.Element | string;
  description?: JSX.Element | string;
}

const ThoughtfulResearchesPhone: React.FC<ThoughtfulResearchesPhoneProps> = (
  {
    description,
    title,
    className
  }
) => {
  const phoneNumber = GetFuseLease()
  const formattedPhoneNumber = phoneNumber?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '$1-$2-$3',
  )

  return (
    <div className={cn("u-flex u-flex-col u-justify-between u-rounded-md u-bg-blue-50 u-p-6 u-w-full md:u-max-w-[unset] u-h-[11.75rem] md:u-h-[15rem] md:u-w-[13.66rem] md:u-p-6 md:u-gap-y-2 lg:u-w-[23.66rem] lg:u-h-[12rem] lg:u-gap-y-0", className)} data-cy="thoughtful-researches-phone">
      <div className="u-flex u-flex-col u-gap-y-1" data-cy="text-container">
        <span className="u-text-indigo-700 t-title-sm-black lg:t-title-base-black" data-cy="title">{title}</span>
        <p className="u-text-gray-700 u-tracking-[0] t-title-xs lg:t-title-sm" data-cy="description">{description}</p>
      </div>
      <span className="u-flex u-items-center u-justify-start u-gap-1 md:u-gap-2 u-bg-blue-500 md:u-bg-transparent u-px-5 u-py-2 md:u-p-0 u-rounded-full u-w-max" data-cy="phone-container">
        <CustomIcon icon="cellPhone" className="u-h-4 u-w-4 md:u-h-5 md:u-w-5 u-text-white md:u-text-blue-500" data-cy="phone-icon" />
        <a
          data-fuse
          data-fuse-format="###-###-####"
          data-fuse-name="TXU-Banner"
          href={`tel:${phoneNumber}`}
          className="t-title-tiny-black md:t-title-xs-black lg:t-title-sm-black u-leading-5 u-text-white md:u-text-blue-500 md:u-underline md:u-leading-6"
          data-cy="phone-link"
        >
          {formattedPhoneNumber}
        </a>
      </span>
    </div>
  );
};

export default ThoughtfulResearchesPhone;
