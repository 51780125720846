import React from 'react'
import { useMonarchRule } from '@redventures/cohesion-utils-react'

import { openExternalLink } from '@/helpers/setCookieAndLinkOff'
import { cn } from '@/lib/utils'
import {
  JOFeaturedCard,
  JOFeaturedCardBadge,
  JOFeaturedCardContent,
  JOFeaturedCardDescription,
  JOFeaturedCardPicture,
  JOFeaturedCardTitle,
} from '../design-system/JOFeaturedCard'
import CustomIcon from '@/components/design-system/CustomIcon'
import ThoughtfulZipEntry from '@/components/sections/ThoughtfulZipEntry'
import { Curve } from '@/components/design-system/Curve'
import useGoogleReview from '@/hooks/useGoogleReview'
import { RatingStars } from '../design-system/rating'

interface Hero {
  heading: string
  subHeading: string
}

const ThoughtfulHero = () => {
  const { value: heroCopy } = useMonarchRule<Hero>('homepageHeroCopy')
  const { rating, totalReviews, linkText, linkUrl } = useGoogleReview()

  const handleFindPlansClick = (URL: string) => {
    openExternalLink(`/${URL}`)
  }

  return (
    <>
      <div
        className={cn(
          'u-flex u-flex-col u-gap-6 u-bg-blue-50 u-px-6 u-pt-10',
          'md:u-grid md:u-grid-cols-2 md:u-pt-16',
          'lg:u-grid-cols-[584px_584px] lg:u-justify-center',
        )}
      >
        <h1
          className={cn(
            't-title-2xl u-text-balance u-text-center u-tracking--tight u-text-blue-600',
            'md:t-title-3xl md:u-text-left',
            'lg:t-title-4xl lg:u-mb-5 lg:u-text-wrap lg:u-leading-[4.5rem]',
          )}
        >
          {heroCopy?.heading || 'Save with energy that fits your life'}
        </h1>

        <div className="u-flex u-max-w-[31.25rem] u-flex-col u-gap-4 u-self-center md:u-row-span-2 md:u-self-center lg:u-max-w-none lg:u-self-end lg:u-pl-[98px]">
          <a
            href="/free-nights-and-solar-days/"
            data-click-tracker
            data-name="Free Nights & Solar Days"
            data-text="100% free all night, 100% solar all day, no panels required."
            data-location="Hero-CARDS"
            data-element-type="LINK"
            data-action-outcome="EXTERNALLINK"
            aria-label="Free Nights & Solar Days"
            data-outcome-url="/free-nights-and-solar-days/"
          >
            <JOFeaturedCard variant="compact" className="sm:u-mx-6">
              <JOFeaturedCardPicture
                src="/images/icons/sun-moon-cloud.svg"
                alt="placeholder"
                width={64}
                height={64}
              />
              <JOFeaturedCardContent>
                <JOFeaturedCardTitle>
                  Free Nights & Solar Days
                </JOFeaturedCardTitle>
                <JOFeaturedCardDescription>
                  100% free all night, 100% solar all day, no panels required.
                </JOFeaturedCardDescription>
              </JOFeaturedCardContent>
              <JOFeaturedCardBadge>
                <CustomIcon icon="dollarCircle" />
                Free Electricity
              </JOFeaturedCardBadge>
            </JOFeaturedCard>
          </a>

          <a
            href="/ultimate-season-pass/"
            data-click-tracker
            data-name="Ultimate Season Pass"
            data-text="Get 50% off peak winter & summer, plus even more months of automatic savings."
            data-location="Hero-CARDS"
            data-element-type="LINK"
            data-action-outcome="EXTERNALLINK"
            aria-label="Ultimate Season Pass"
            data-outcome-url="/ultimate-season-pass/"
            className="u-hidden md:u-grid"
          >
            <JOFeaturedCard
              variant="compact"
              className="u-hidden sm:u-grid lg:u-py-7"
            >
              <JOFeaturedCardPicture
                src="/images/icons/sun-moon-cloud.svg"
                alt="placeholder"
                width={64}
                height={64}
              />
              <JOFeaturedCardContent>
                <JOFeaturedCardTitle className="sm:u-text-4 lg:u-text-[1.375rem]">
                  Ultimate Season Pass
                </JOFeaturedCardTitle>
                <JOFeaturedCardDescription>
                  Get 50% off peak winter & summer, plus even more months of
                  automatic savings.
                </JOFeaturedCardDescription>
              </JOFeaturedCardContent>
              <JOFeaturedCardBadge className="lg:u-h-[1.625rem] lg:u-text-[0.813rem]">
                <CustomIcon icon="dollarCircle" />
                Season Savings
              </JOFeaturedCardBadge>
            </JOFeaturedCard>
          </a>

          <a
            href="/live-your-free/"
            data-click-tracker
            data-name="Live Your Free"
            data-text="Get automatic free energy whenever you use it most."
            data-location="Hero-CARDS"
            data-element-type="LINK"
            data-action-outcome="EXTERNALLINK"
            aria-label="Live Your Free"
            data-outcome-url="/live-your-free/"
            className="u-hidden md:u-grid"
          >
            <JOFeaturedCard
              variant="compact"
              className="u-hidden sm:u-mx-6 sm:u-grid"
            >
              <JOFeaturedCardPicture
                src="/images/icons/sun-moon-cloud.svg"
                alt="placeholder"
                width={64}
                height={64}
              />
              <JOFeaturedCardContent>
                <JOFeaturedCardTitle>Live Your Free</JOFeaturedCardTitle>
                <JOFeaturedCardDescription>
                  Get automatic free energy whenever you use it most.
                </JOFeaturedCardDescription>
              </JOFeaturedCardContent>
              <JOFeaturedCardBadge>
                <CustomIcon icon="dollarCircle" />
                Free Electricity
              </JOFeaturedCardBadge>
            </JOFeaturedCard>
          </a>
        </div>

        <ThoughtfulZipEntry onFindPlansClick={handleFindPlansClick} />

        <div>
          <div className="u-inline-flex u-w-full u-items-stretch u-justify-center u-gap-2 u-text-sm lg:u-text-4">
            <RatingStars
              rating={rating}
              className="[&_svg]:u-w-[0.875rem] md:[&_svg]:u-h-5 md:[&_svg]:u-w-4 lg:[&_svg]:u-h-6 lg:[&_svg]:u-w-6"
            />
            <CustomIcon
              icon="google"
              additionalClasses="u-h-4 lg:u-h-6 u-self-center"
            />
            <div className="u-flex u-items-center u-gap-1 u-text-[#515A72]">
              <span>Rated {rating}</span>
              <div className="u-h-full u-w-px u-border-l u-border-[#515A72]" />
              <span>{totalReviews}</span>
            </div>
            <a
              className="u-text-blue-600 u-underline"
              target="_blank"
              rel="noreferrer noopener"
              href={linkUrl}
            >
              {linkText}
            </a>
          </div>
        </div>
      </div>
      <div className="u-bg-blue-50 u-pt-4 md:u-pt-8 lg:u-pt-[5%]">
        <Curve variant={11} />
      </div>
    </>
  )
}

export default ThoughtfulHero
