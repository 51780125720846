import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export type ValuesItemProps = HTMLAttributes<HTMLDivElement>;

const ValuesItem = ({ className, ...props}: ValuesItemProps) => {
    return (
        <div
            data-cy="values-item"
            {...props}
            className={
                cn(
                    "u-flex u-flex-col u-items-center u-max-w-[calc(100% - 48px)] lg:u-grid lg:u-grid-cols-[7.5rem_20rem] lg:u-gap-x-10 md:u-flex-shrink md:u-flex-grow lg:u-basis-[0%]",
                    "[&_img]:u-w-16 [&_img]:u-aspect-square lg:[&_img]:u-row-span-2 lg:[&_img]:u-w-full",
                    "[&_h3]:u-text-white [&_h3]:u-text-6 [&_h3]:u-font-900 [&_h3]:u-mt-6 [&_h3]:u-text-center",
                    "lg:[&_h3]:u-self-start lg:[&_h3]:u-mt-0 lg:[&_h3]:u-text-8 lg:[&_h3]:u-text-left",
                    "[&_p]:u-text-white [&_p]:u-text-4 [&_p]:u-font-400 [&_p]:u-text-center [&_p]:u-mt-2 [&_p]:u-text-balance [&_p]:u-leading-6 md:[&_p]:u-text-pretty md:[&_p]:u-max-w-full",
                    "lg:[&_p]:u-text-left lg:[&_p]:u-text-4.5 lg:[&_p]:u-mt-4 lg:[&_p]:u-leading-[1.625rem]",
                    className
                )
            }
        />
    );
};

export { ValuesItem };
