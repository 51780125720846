import React from 'react'
import { useMonarchRule } from '@redventures/cohesion-utils-react'

import CustomIcon from '@/components/design-system/CustomIcon'
import PriceFocusedZipEntry from '@/components/sections/PriceFocusedZipEntry'
import { Curve } from '@/components/design-system/Curve'
import { RatingStars } from '@/components/design-system/rating'
import useGoogleReview from '@/hooks/useGoogleReview'
import { openExternalLink } from '@/helpers/setCookieAndLinkOff'
import {
  GOOGLE_REVIEW_RATING,
  GOOGLE_REVIEW_TEXT,
  GOOGLE_REVIEW_TOTAL_REVIEWS,
  GOOGLE_REVIEW_URL,
} from '@/constants/google-reviews-constants'

interface Hero {
  heading: string
  subHeading: string
}

const PriceFocusedHero = () => {
  const { value: heroCopy } = useMonarchRule<Hero>('homepageHeroCopy')
  const { rating, totalReviews, linkText, linkUrl } = useGoogleReview()

  const handleFindPlansClick = (URL: string) => {
    openExternalLink(`/${URL}`)
  }

  return (
    <div className="u-bg-gradient-to-r-blue-blue">
      <div className="u-mx-auto u-grid u-max-w-lg u-gap-5 u-px-6 u-pb-7 u-pt-8 md:u-max-w-none md:u-grid-cols-2 md:u-grid-rows-2 md:u-gap-4 md:u-gap-x-8 md:u-p-8 lg:u-mx-auto lg:u-max-w-[75rem] lg:u-gap-x-10">
        <h1 className="t-title-2xl md:t-title-3xl u-text-white">
          {heroCopy?.heading || 'Savings you can count on'}
        </h1>
        <div className="u-grid u-grid-cols-2 u-gap-4 md:u-col-start-1 md:u-gap-7">
          <div className="u-flex u-flex-col u-items-start u-gap-2">
            <CustomIcon icon="shield" className="u-h-8 u-w-8" />
            <div className="u-flex u-flex-col u-items-start u-gap-1">
              <h2 className="t-title-xs-bold md:u-title-xs-bold u-text-white">
                Price Protect Promise
              </h2>
              <p className="t-body-sm u-text-white">
                Rest easy with a steady rate protected from rising energy costs.
                <sup>1</sup>
              </p>
            </div>
          </div>
          <div className="u-flex u-flex-col u-items-start u-gap-2">
            <CustomIcon icon="paid" className="u-h-8 u-w-8" />
            <div className="u-flex u-flex-col u-items-start u-gap-1">
              <h2 className="t-title-xs-bold md:u-title-xs-bold u-text-white">
                Free Electricity
              </h2>
              <p className="t-body-sm u-text-white">
                Some of our most popular plans give you free energy when you
                need it most.
              </p>
            </div>
          </div>
        </div>
        <div className="u-flex u-flex-col u-gap-4 md:u-relative md:u-z-10 md:u-col-start-2 md:u-row-span-2 md:u-row-start-1 md:u-self-center">
          <PriceFocusedZipEntry onFindPlansClick={handleFindPlansClick} />
          <div className="lg:u-text-4xl u-inline-flex u-w-full u-items-center u-justify-center u-gap-2 u-text-sm">
            <RatingStars
              className="[&_svg]:u-w-[0.875rem] md:[&_svg]:u-h-4 md:[&_svg]:u-w-4"
              rating={rating || GOOGLE_REVIEW_RATING}
            />
            <span className="u-flex u-items-center u-justify-center u-rounded-full u-bg-white u-p-0.5">
              <CustomIcon
                additionalClasses="u-h-3 u-w-3 u-self-center"
                icon="google"
              />
            </span>
            <div className="u-flex u-items-center u-gap-1 u-text-white">
              <span>
                Rated {''}
                <span className="t-title-xs-bold u-text-white">
                  {rating || GOOGLE_REVIEW_RATING}
                </span>
              </span>
              <div className="u-h-3.5 u-w-px u-border-l u-border-white" />
              <span>{totalReviews || GOOGLE_REVIEW_TOTAL_REVIEWS}</span>
            </div>
            <a
              className="u-text-white u-underline u-underline-offset-4"
              href={linkUrl || GOOGLE_REVIEW_URL}
              rel="noreferrer noopener"
              target="_blank"
            >
              {linkText || GOOGLE_REVIEW_TEXT}
            </a>
          </div>
        </div>
      </div>
      <div className="u-pt-4 md:u-pt-0 lg:u-pt-[2%]">
        <Curve variant={11} />
      </div>
    </div>
  )
}

export default PriceFocusedHero
