import { GetFuseLease } from '@/helpers/fuse'
import { Button } from '../design-system/Button'
import CustomIcon from '../design-system/CustomIcon'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../design-system/Tabs'
import { Wizard, Step } from '../design-system/Wizard'
import {
  ZipEntryForm,
  ZipEntryFormButton,
  ZipEntryFormError,
  ZipEntryFormInput,
  ZipEntryFormInputWrapper,
  ZipEntryFormTitle,
} from '../design-system/ZipEntryForm'
import { useZipEntryForm } from '@/hooks/useZipEntryForm'
import { cn } from '@/lib/utils'

type ThoughtfulZipEntryProps = {
  /** callback to be called when the user clicks Find Plans button */
  onFindPlansClick?: (this: void, URL: string) => void
}

const ThoughtfulZipEntry = ({ onFindPlansClick }: ThoughtfulZipEntryProps) => {
  const {
    formState: {
      zipCode,
      showZipCodeError,
      showMovingError,
      showApartmentError,
    },
    zipCodeRef,
    handlers,
  } = useZipEntryForm({ onFindPlansClick })

  const phoneNumber = GetFuseLease()
  const formattedPhoneNumber = phoneNumber?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '$1-$2-$3',
  )

  return (
    <Tabs defaultValue="1" className="lg:u-mb-7">
      <TabsList>
        <TabsTrigger value="1">New to TXU Energy</TabsTrigger>
        <TabsTrigger value="2">Current Customer</TabsTrigger>
      </TabsList>
      <TabsContent value="1" className="lg:u-rounded-tl-none lg:u-pb-6">
        <Wizard>
          {({ nextStep }) => (
            <>
              <Step>
                <ZipEntryForm
                  variant="researcher"
                  onSubmit={(e) => handlers.handleFirstStepSubmit(e, nextStep)}
                >
                  <ZipEntryFormTitle className="u-text-3.5 u-font-400">
                    <span className="md:u-hidden">
                      Enter your ZIP Code to see plans or give us a call
                    </span>
                    <span className="u-hidden md:u-inline">
                      Enter your ZIP Code to see plans
                    </span>
                  </ZipEntryFormTitle>
                  <ZipEntryFormInputWrapper>
                    <ZipEntryFormInput
                      ref={zipCodeRef}
                      value={zipCode}
                      onChange={handlers.handleZipCodeChange}
                      onFocus={handlers.handleZipCodeFocus}
                      id="zipCode"
                      name="zipCode"
                      type="tel"
                      pattern="[0-9]{5}"
                      required
                      maxLength={5}
                      placeholder="ZIP Code"
                      autoComplete="off"
                    />
                    <ZipEntryFormButton>Continue</ZipEntryFormButton>
                    <ZipEntryFormError showError={showZipCodeError}>
                      Please enter a valid ZIP Code
                    </ZipEntryFormError>
                  </ZipEntryFormInputWrapper>
                  <Button className="u-border-white u-bg-blue-600 hover:u-border-white md:u-border-none [&_svg]:u-size-5">
                    <span className="u-hidden u-font-400 md:u-inline">
                      Or call us at
                    </span>
                    <CustomIcon icon="cellPhone" />
                    <a
                      data-fuse
                      data-fuse-format="###-###-####"
                      data-fuse-name="TXU-Banner"
                      href={`tel:${phoneNumber}`}
                      className="t-title-sm-black lg:t-title-base-black u-leading-5 u-text-white md:u-leading-6 lg:u-underline"
                    >
                      {formattedPhoneNumber}
                    </a>
                  </Button>
                </ZipEntryForm>
              </Step>
              <Step>
                <form
                  onSubmit={handlers.handleSecondStepSubmit}
                  className="u-flex u-w-full u-flex-col u-gap-3"
                  noValidate
                >
                  <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                    <legend className="u-mb-2 u-text-center lg:u-float-left">
                      Are you moving to a new address?
                    </legend>
                    <div className="u-group u-relative u-float-right u-flex u-gap-3">
                      <label
                        data-cy="moving-radio-mYesLabel"
                        id="mYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="movey"
                          name="m"
                          required
                          aria-labelledby="mYesLabel"
                          onClick={() =>
                            handlers.handleRadioClick(
                              'Move/Switch',
                              'Yes',
                              'User indicates Yes they are moving',
                            )
                          }
                          className={cn(
                            'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                            "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                          )}
                        />
                        Yes
                      </label>

                      <label
                        data-cy="moving-radio-mNoLabel"
                        id="mNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="moven"
                          name="m"
                          required
                          aria-labelledby="mNoLabel"
                          onClick={() =>
                            handlers.handleRadioClick(
                              'Move/Switch',
                              'No',
                              'User indicates No they are not moving',
                            )
                          }
                          className={cn(
                            'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                            "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                          )}
                        />
                        No
                      </label>
                      <ZipEntryFormError showError={showMovingError}>
                        Please select one of these options.
                      </ZipEntryFormError>
                    </div>
                  </fieldset>
                  <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                    <legend className="u-mb-2 u-text-center lg:u-float-left">
                      Are you moving to an apartment?
                    </legend>
                    <div className="u-group u-relative u-float-right u-flex u-gap-3">
                      <label
                        data-cy="apartment-radio-aYesLabel"
                        id="aYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmenty"
                          name="a"
                          required
                          aria-labelledby="aYesLabel"
                          className={cn(
                            'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                            "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                          )}
                        />
                        Yes
                      </label>

                      <label
                        data-cy="apartment-radio-aNoLabel"
                        id="aNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-rounded-xs u-bg-white u-px-3 u-py-2 u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmentn"
                          name="a"
                          required
                          aria-labelledby="aNoLabel"
                          className={cn(
                            'u-grid u-aspect-square u-w-5 u-appearance-none u-place-content-center u-rounded-full u-border-2 u-border-gray-500',
                            "checked:before:u-aspect-square checked:before:u-w-3 checked:before:u-rounded-full checked:before:u-bg-blue-500 checked:before:u-content-['']",
                          )}
                        />
                        No
                      </label>
                      <ZipEntryFormError showError={showApartmentError}>
                        Please select one of these options.
                      </ZipEntryFormError>
                    </div>
                  </fieldset>
                  <Button
                    variant="primary"
                    type="submit"
                    data-cy="find-plans-button"
                    className="u-mt-3 hover:u-bg-blue-500 focus:u-bg-blue-500"
                  >
                    Find Plans
                  </Button>
                </form>
              </Step>
            </>
          )}
        </Wizard>
      </TabsContent>
      <TabsContent value="2" className="lg:u-rounded-tl-none">
        <div className="u-flex u-flex-col u-items-center u-gap-4">
          <h3 className="u-text-base u-font-600 lg:u-text-4.5">
            Sign in to see plans
          </h3>
          <Button
            variant="primary"
            size="large"
            onClick={handlers.handleSignInClick}
            className="u-w-full md:u-w-auto md:u-px-14"
          >
            Sign In
          </Button>
        </div>
      </TabsContent>
    </Tabs>
  )
}

export default ThoughtfulZipEntry
