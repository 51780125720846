import { useMonarch } from '@redventures/cohesion-utils-react'

import { Curve } from '@/components/design-system/Curve'
import HeroSection from '@/components/sections/HeroSection'
import ThoughtfulHero from '@/components/sections/ThoughtfulHero'
import HeroSectionNewToTxu from '@/components/sections/HeroSectionNewToTxu'
import PriceFocusedHero from '@/components/sections/PriceFocusedHero'
import HeroSectionHighIntent from '@/components/sections/HeroSectionHighIntent'

interface HeroData {
  hero?: string
  [key: string]: any
}

const HeroSwitcher = (): React.ReactElement => {
  const { rules } = useMonarch()
  const {
    trafficFlow,
  }: { trafficFlow?: { value?: { componentCustomId?: string; data?: any } } } =
    rules
  const isJoComponent =
    trafficFlow?.value?.componentCustomId === 'joHomepageVersion'
  const data: HeroData = isJoComponent
    ? (trafficFlow?.value?.data as HeroData) || {}
    : {}

  if (data?.hero === 'thoughtful') {
    return <ThoughtfulHero />
  }

  if (data?.hero === 'newToTXU') {
    return <HeroSectionNewToTxu />
  }

  if (data?.hero === 'priceFocus') {
    return <PriceFocusedHero />
  }

  if (data?.hero === 'highIntent') {
    return <HeroSectionHighIntent />
  }

  return (
    <>
      <div className="u-bg-gradient-hero-section">
        <HeroSection />
      </div>
      <Curve variant={7} />
    </>
  )
}

export default HeroSwitcher
