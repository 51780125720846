import React from 'react';
import PriceFocused, { PriceFocusedProps } from './variants/PriceFocused'
import HighIntent, { HighIntentProps } from './variants/HighIntent'
import NewToTXU, { NewToTXUProps } from './variants/NewToTXU'
import ThoughtfulResearches, { ThoughtfulResearchesProps } from './variants/ThoughtfulResearches'
import ThoughtfulResearchesPhone, {
  ThoughtfulResearchesPhoneProps,
} from '@/components/design-system/JOValueProp/variants/ThoughtfulResearchesPhone'

export interface BaseProps {
  className?: string;
}

type ValuePropsVariant =
  | { variant: 'price-focused'; props: PriceFocusedProps }
  | { variant: 'high-intent'; props: HighIntentProps }
  | { variant: 'new-to-txu'; props: NewToTXUProps }
  | { variant: 'thoughtful-researches'; props: ThoughtfulResearchesProps }
  | { variant: 'thoughtful-researches-phone'; props: ThoughtfulResearchesPhoneProps };

// Variant component mapping
const VARIANT_COMPONENTS = {
  'price-focused': PriceFocused,
  'high-intent': HighIntent,
  'new-to-txu': NewToTXU,
  'thoughtful-researches': ThoughtfulResearches,
  'thoughtful-researches-phone': ThoughtfulResearchesPhone,
} as const;

const ValueProps: React.FC<ValuePropsVariant> = ({ variant, props }) => {
  const Component = VARIANT_COMPONENTS[variant];
  return <Component {...props} />;
};

export default ValueProps;
