import { useState, useRef } from 'react'
import validateZip from '@/helpers/validateZip'
import { useAnalytics } from './useAnalytics'
import { useClickOutside } from './useOnClickOutside'

type ZipFormState = {
  zipCode: string
  wasZipCodeFocused: boolean
  showZipCodeError: boolean
  showMovingError: boolean
  showApartmentError: boolean
  wasRadioClicked: boolean
}

type ZipFormOptions = {
  onFindPlansClick?: (this: void, URL: string) => void
}

export const useZipEntryForm = (options: ZipFormOptions = {}) => {
  const { onFindPlansClick } = options
  const analytics = useAnalytics()

  // Form state
  const [formState, setFormState] = useState<ZipFormState>({
    zipCode: '',
    wasZipCodeFocused: false,
    showZipCodeError: false,
    showMovingError: false,
    showApartmentError: false,
    wasRadioClicked: false,
  })

  // References
  const zipCodeRef = useRef(null)

  // Setup click outside handling
  useClickOutside(zipCodeRef, () => {
    setFormState((prev) => ({
      ...prev,
      showZipCodeError: false,
    }))
  })

  // Form handlers
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '')
    setFormState((prev) => ({
      ...prev,
      showZipCodeError: false,
      zipCode: value,
    }))
  }

  const handleZipCodeFocus = () => {
    if (formState.wasZipCodeFocused) {
      return
    }

    setFormState((prev) => ({
      ...prev,
      wasZipCodeFocused: true,
    }))

    analytics.trackFormStarted('Zip Search Form', 'Zip Check')
  }

  const handleFirstStepSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    callback?: () => void,
  ) => {
    e.preventDefault()
    const value = new FormData(e.currentTarget).get('zipCode') as string

    if (!validateZip(value)) {
      setFormState((prev) => ({
        ...prev,
        showZipCodeError: true,
      }))
      return
    }

    if (validateZip(value)) {
      setFormState((prev) => ({
        ...prev,
        showZipCodeError: false,
      }))

      analytics.trackElementClicked({
        location: value,
        position: 'zipEntry',
        elementType: 'cta Button',
        text: 'CONTINUE (Not Existing Customer)',
        outboundUrl: 'shop.txu.com',
      })

      if (callback && typeof callback === 'function') {
        callback()
      }
    }
  }

  const handleRadioClick = (
    location: string,
    text: string,
    actionOutcome: string,
  ) => {
    if (formState.wasRadioClicked) {
      return
    }

    setFormState((prev) => ({
      ...prev,
      wasRadioClicked: true,
    }))

    analytics.trackElementClicked({
      location,
      elementType: 'CTA button',
      text,
      actionOutcome,
    })
  }

  const handleSecondStepSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    let URL = `plans/?zipCode=${formState.zipCode}&`

    if (!formData.has('m')) {
      setFormState((prev) => ({
        ...prev,
        showMovingError: true,
      }))
      return
    }

    setFormState((prev) => ({
      ...prev,
      showMovingError: false,
    }))

    if (!formData.has('a')) {
      setFormState((prev) => ({
        ...prev,
        showApartmentError: true,
      }))
      return
    }

    setFormState((prev) => ({
      ...prev,
      showApartmentError: false,
    }))

    const queryString = new URLSearchParams(
      Array.from(formData.entries()).map(([key, value]) => [
        key,
        typeof value === 'string' ? value : String(value),
      ]),
    ).toString()

    URL += queryString

    analytics.trackFormSubmitted({
      formType: 'Zip Box',
      formName: 'ZIP/Address Search Form',
      outboundUrl: URL,
      fieldType: 'cta Button',
      fieldName: 'SHOP',
    })

    if (onFindPlansClick && typeof onFindPlansClick === 'function') {
      setTimeout(() => {
        onFindPlansClick(URL)
      }, 500)
    }
  }

  const handleSignInClick = () => {
    analytics.trackElementClicked({
      location: 'Tabbed Zip Hero',
      position: 'zipEntry',
      elementType: 'cta Button',
      text: 'CONTINUE (Existing Customer)',
    })

    setTimeout(() => {
      window.location.href = 'https://www.txu.com/signin'
    }, 500)
  }

  return {
    formState,
    zipCodeRef,
    handlers: {
      handleZipCodeChange,
      handleZipCodeFocus,
      handleFirstStepSubmit,
      handleRadioClick,
      handleSecondStepSubmit,
      handleSignInClick,
    },
  }
}
