"use client"
import { BaseProps } from '@/components/design-system/JOValueProp'
import { cn } from '@/lib/utils'
import CustomIcon from '@/components/design-system/CustomIcon'
import { useRouter } from 'next/navigation'
import { JSX } from 'react'

export interface ThoughtfulResearchesProps extends BaseProps {
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  cta?: string;
  linkCta?: string;
}

const ThoughtfulResearches: React.FC<ThoughtfulResearchesProps> = (
  {
    cta,
    linkCta,
    description,
    title,
    className
  }
) => {
  const router = useRouter();

  const handleOnClickCta = () => {
    router.push(linkCta!)
  }

  return (
    <div
      className={cn("u-flex u-flex-col u-justify-between u-rounded-md u-bg-gray-150 u-p-3 u-w-[9.75rem] u-h-[14rem] md:u-h-[15rem] md:u-w-[13.66rem] md:u-p-6 md:u-gap-y-2 lg:u-w-[23.66rem] lg:u-h-[12rem] lg:u-gap-y-0", className)}
      data-cy="thoughtful-researches"
    >
      <div className="u-flex u-flex-col u-gap-y-1" data-cy="text-container">
        <span
          className="u-text-gray-700 t-title-xs-black md:t-title-sm-black lg:t-title-base-black"
          data-cy="title"
        >
          {title}
        </span>
        <p
          className="u-text-gray-600 u-tracking-[0] t-title-tiny md:t-title-xs lg:t-title-sm"
          data-cy="description"
        >
          {description}
        </p>
      </div>
      <button
        onClick={handleOnClickCta}
        className="u-flex u-items-center u-gap-x-1 t-title-tiny-bold u-text-blue-500 md:t-body-lg-black"
        data-cy="cta-button"
      >
        {cta}
        <CustomIcon
          icon="arrowRightSmooth"
          className="u-w-4 u-h-4 md:u-w-5 md:u-h-5"
          data-cy="cta-icon"
        />
      </button>
    </div>
  );
};

export default ThoughtfulResearches;
