import { BaseProps } from '@/components/design-system/JOValueProp'
import CustomIcon, { IconKeys } from '@/components/design-system/CustomIcon'
import { JSX } from 'react'

export interface NewToTXUProps extends BaseProps {
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  icon?: IconKeys;
}

const NewToTXU: React.FC<NewToTXUProps> = ({ title, description, icon }) => {
  return (
    <div className="u-flex u-gap-6 u-gap-y-1 u-w-full u-items-center md:u-flex-col md:u-items-start md:u-gap-y-6 md:u-w-[13.6625rem] lg:u-w-[25rem] lg:u-p-6" data-cy="new-to-txu">
      <div className="u-w-12 u-h-12 u-flex-shrink-0 md:u-w-16 md:u-h-16" data-cy="icon-container">
        <CustomIcon icon={icon!} data-cy="custom-icon" />
      </div>
      <div className="u-flex u-flex-col u-gap-y-1 md:u-gap-2" data-cy="text-container">
        <span className="u-text-gray-700 t-title-xs-black md:t-title-sm-black u-whitespace-nowrap" data-cy="title">{title}</span>
        <p className="u-text-gray-550 u-tracking-[0] t-title-tiny md:t-body-lg md:t-title-xs" data-cy="description">{description}</p>
      </div>
    </div>
  );
};

export default NewToTXU;
