import { beam } from '@/helpers/beam'

type FormStartedPayload = {
  formContext: {
    formName: string
    formType: string
  }
}

type ElementClickedPayload = {
  webElement: {
    location: string
    position?: string
    elementType: string
    text: string
  }
  actionOutcome?: string
  outboundUrl?: string
}

type FormSubmittedPayload = {
  formContext: {
    formType: string
    formName: string
  }
  outboundUrl?: string
  field?: Array<{
    fieldType: string
    fieldName: string
  }>
}

export const useAnalytics = () => {
  const trackFormStarted = (formName: string, formType: string) => {
    const payload: FormStartedPayload = {
      formContext: {
        formName,
        formType,
      },
    }

    beam('FormStarted', payload)
  }

  const trackElementClicked = (options: {
    location: string
    position?: string
    elementType: string
    text: string
    actionOutcome?: string
    outboundUrl?: string
  }) => {
    const {
      location,
      position,
      elementType,
      text,
      actionOutcome,
      outboundUrl,
    } = options

    const payload: ElementClickedPayload = {
      webElement: {
        location,
        ...(position && { position }),
        elementType,
        text,
      },
      ...(actionOutcome && { actionOutcome }),
      ...(outboundUrl && { outboundUrl }),
    }

    beam('ElementClicked', payload)
  }

  const trackFormSubmitted = (options: {
    formType: string
    formName: string
    outboundUrl?: string
    fieldType?: string
    fieldName?: string
  }) => {
    const { formType, formName, outboundUrl, fieldType, fieldName } = options

    const payload: FormSubmittedPayload = {
      formContext: {
        formType,
        formName,
      },
      ...(outboundUrl && { outboundUrl }),
      ...(fieldType &&
        fieldName && {
          field: [
            {
              fieldType,
              fieldName,
            },
          ],
        }),
    }

    beam('FormSubmitted', payload)
  }

  return {
    trackFormStarted,
    trackElementClicked,
    trackFormSubmitted,
  }
}
