"use client"

import React, { forwardRef } from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import { Button } from "../Button"
import ExclamationIcon from "../../../../public/images/icons/exclamation.svg"

type ZipEntryFormContextValue = {
    variant: "default" | "geo" | "researcher"
}

const ZipEntryFormContext = React.createContext<ZipEntryFormContextValue | undefined>(undefined)
type ZipEntryFormContextProviderProps = ZipEntryFormContextValue & {
    children: React.ReactNode
}

export const ZipEntryFormContextProvider = ({ variant, children }: ZipEntryFormContextProviderProps) => {
    return (
        <ZipEntryFormContext.Provider
            value={{
                variant
            }}
        >
            {children}
        </ZipEntryFormContext.Provider>
    )
}

export const useZipEntryFormContext = () => {
    const context = React.useContext(ZipEntryFormContext)

    if (!context) {
        throw new Error("useZipEntryFormContext must be used within a ZipEntryFormContext Provider")
    }

    return context
}

const ZipEntryFormVariants = cva(
    cn(
        "u-flex u-flex-col u-w-full u-gap-4",
    ),
    {
        variants: {
            variant: {
                default: "",
                geo: "",
                researcher: "",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)

export interface ZipEntryFormProps
    extends React.HTMLAttributes<HTMLFormElement>,
    VariantProps<typeof ZipEntryFormVariants> {
}

export const ZipEntryForm = ({ children, className, variant, ...props }: ZipEntryFormProps) => {
    return (
        <ZipEntryFormContextProvider variant={variant || "default"}>
            <form
                data-cy="zip-entry-form"
                {...props}
                className={cn(ZipEntryFormVariants({ variant, className }))}
                noValidate
            >
                {children}
            </form>
        </ZipEntryFormContextProvider>
    )
}

// title
const ZipEntryFormTitleVariants = cva(
    "u-font-600 u-text-base u-text-center u-text-white lg:u-text-4.5",
    {
        variants: {
            variant: {
                default: "",
                geo: "",
                researcher: "",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
interface ZipEntryFormTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export const ZipEntryFormTitle = ({ children, className, ...props }: ZipEntryFormTitleProps) => {
    const { variant } = useZipEntryFormContext()

    return (
        <h3
            data-cy="zip-entry-form-title"
            {...props}
            className={cn(ZipEntryFormTitleVariants({ variant, className }))}
        >
            {children}
        </h3>
    )
}

// input wrapper
const ZipEntryFormInputWrapperVariants = cva(
    "u-relative u-w-full u-flex u-flex-col u-gap-3 lg:u-flex-row lg:u-gap-4",
    {
        variants: {
            variant: {
                default: "lg:u-bg-white lg:u-rounded-full lg:u-p-1",
                geo: "md:u-bg-transparent md:u-rounded-none md:u-p-0 md:u-flex-col lg:u-flex-row",
                researcher: "u-flex-row u-bg-white u-rounded-full u-border-gray-300 u-p-1",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
interface ZipEntryFormInputWrapperProps extends React.HTMLAttributes<HTMLDivElement> {}
export const ZipEntryFormInputWrapper = ({ children, className, ...props }: ZipEntryFormInputWrapperProps) => {
    const { variant } = useZipEntryFormContext()

    return (
        <div
            data-cy="zip-entry-form-input-wrapper"
            {...props}
            className={cn(ZipEntryFormInputWrapperVariants({ variant, className }))}
        >
            {children}
        </div>
    )
}

// input
const ZipEntryFormInputVariants = cva(
    "u-py-3 u-px-4 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-600 u-text-gray-700 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker md:u-outline-none u-leading-[1.625rem] lg:u-leading-6 u-pb-3.5 u-pt-4 focus:u-outline-none",
    {
        variants: {
            variant: {
                default: "",
                geo: "u-rounded-none md:u-py-4 lg:u-py-[0.75rem]",
                researcher: "u-py-2",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)

interface ZipEntryFormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
export const ZipEntryFormInput = forwardRef<HTMLInputElement, ZipEntryFormInputProps>(({ className, ...props }, ref) => {
    const { variant } = useZipEntryFormContext()

    return (
        <input
            ref={ref}
            data-cy="zip-entry-form-input"
            type="tel"
            pattern="[0-9]{5}"
            required
            maxLength={5}
            placeholder="ZIP Code"
            autoComplete="off"
            name="zipCode"
            {...props}
            className={cn(ZipEntryFormInputVariants({ variant, className }))}
        />
    )
})

// button
const ZipEntryFormButtonVariants = cva(
    cn(
        "hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-6 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none",
    ),
    {
        variants: {
            variant: {
                default: "",
                geo: "md:u-py-4 lg:u-py-[0.688rem]",
                researcher: "u-py-2.5 lg:u-py-4",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    }
)
interface ZipEntryFormButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const ZipEntryFormButton = ({ children, className, ...props }: ZipEntryFormButtonProps) => {
    const { variant } = useZipEntryFormContext()

    return (
        <Button
            data-cy="zip-entry-form-button"
            {...props}
            className={cn(ZipEntryFormButtonVariants({ variant, className }))}
        >
            {children}
        </Button>
    )
}

// error
interface ZipEntryFormErrorProps extends React.HTMLAttributes<HTMLDivElement> {
    showError?: boolean
}
export const ZipEntryFormError = ({ children, className, showError, ...props }: ZipEntryFormErrorProps) => {

    return (
        <div
            data-cy="zip-entry-form-error"
            {...props}
            className={cn(
                "u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden u-z-10",
                "u-absolute u-top-full u-items-center u-gap-2",
                "before:u-content-[''] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4",
                showError && "u-flex",
                className
            )}
        >
            <ExclamationIcon />
            {children}
        </div>
    )
}
