import { cn } from "@/lib/utils"
import CustomIcon, { CustomIconProps } from "@/components/design-system/CustomIcon"

interface RatingProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Rating = ({ children, className, ...props }: RatingProps) => {
    return (
        <div
            data-cy="rating"
            {...props}
            className={cn("u-flex u-gap-1", className)}
        >
            {children}
        </div>
    )
}

// star
interface StarProps extends CustomIconProps {
    icon: "starEmpty" | "starHalf" | "starFull"
}

export const Star = ({ className, ...props }: StarProps) => {
    return (
        <CustomIcon className={cn("u-w-4 u-h-4", className)} {...props} />
    )
}

// rating
interface RatingStarsProps extends React.HTMLAttributes<HTMLDivElement> {
    rating: number
}

export const RatingStars = ({ rating, className, ...props }: RatingStarsProps) => {
    return (
        <div
            data-cy="rating-value"
            {...props}
            className={cn("u-flex u-gap-1 u-items-center", className)}
        >
            {Array.from({ length: 5 }, (_, i) => {
                if (rating >= i + 1) {
                    return <Star key={i} icon="starFull" />
                } else if (rating >= i + 0.5) {
                    return <Star key={i} icon="starHalf" />
                } else {
                    return <Star key={i} icon="starEmpty" />
                }
            })}
        </div>
    )
}
